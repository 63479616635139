<template>
    <div class="liefeng-picture-group" v-if="data && data.length > 0">
        <img
            v-if="item.image"
            class="item"
            v-for="(item, index) in data"
            :key="id + index"
            :src="item.image"
            :style="{
                margin: Y(item.marginTop) + ' ' + X(item.marginRight) + ' ' + Y(item.marginBottom) + ' ' + X(item.marginLeft),
                width: X(item.width),
                height: Y(item.height),
                'border-radius': item.radius ? 10 : '',
            }"
            :id="item.id"
            @click="click(item)"
        />
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        data: {
            //images数组
            type: Array,
            default: () => {
                return [
                    // {
                    //   image: "./apps/suiyue/images/remote/banner_03.png",
                    //   url: "",
                    //   width: "50%",
                    //   height: "100px",
                    //   marginLeft: "0",
                    //   marginRight: "0",
                    //   marginTop: "0",
                    //   marginBottom: "0",
                    //   radius: true,
                    // }
                ]
            },
        },
        wp: { type: Number, default: 1 },
        hp: { type: Number, default: 1 },
    },
    data() {
        return {
            id: this.$core.createID(),
        }
    },
    created() {},

    methods: {
        click(item) {
            var params = {}
            // params.code = item.code;
            if (item.action) {
                params.jumpJson = item.action
            } else {
                params.url = item.url
            }
            this.$emit("on-click", params)
        },

        X(w) {
            if (!w || (typeof w !== "number" && !/^[0-9]+(\.[0-9]+)?$/.test(w))) {
                return w || "auto"
            }
            return w * this.wp + "px"
        },

        Y(h) {
            if (!h || (typeof h !== "number" && !/^[0-9]+(\.[0-9]+)?$/.test(h))) {
                return h || "auto"
            }
            return h * this.hp + "px"
        },
    },
}
</script>
<style lang="less">
.liefeng-picture-group {
    width: 100%;

    > .item {
        cursor: pointer;
    }
}
</style>
